import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@system/core/config';

export const APP_CONFIG_FMS_PROD: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'production',
  production: false,
  apiUrl: 'https://system-api.frm.feedlothealth.com/api',
  signalRUrl: 'https://system-api.frm.feedlothealth.com/PushNotification',
  heartbeatUrl: 'https://system-api.frm.feedlothealth.com/api/Heartbeat',
  showDevFeature: true,
  authServerUrl: 'https://identity.frm.feedlothealth.com',
  psmtReportingUrl: 'https://psmt-reporting.frm.feedlothealth.com',
  integrations: {
    sentryDsn: 'https://6d251ba3821b9e7afb59401d8dc007b1@o4506538482532352.ingest.us.sentry.io/4508015587885056'
  }
};
