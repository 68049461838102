import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@system/core/config';

export const APP_CONFIG_FMS_QA: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'qa',
  production: false,
  apiUrl: 'https://system-api.qa.fms.feedlothealth.com/api',
  signalRUrl: 'https://system-api.qa.fms.feedlothealth.com/PushNotification',
  heartbeatUrl: 'https://system-api.qa.fms.feedlothealth.com/api/Heartbeat',
  showDevFeature: true,
  authServerUrl: 'https://identity.qa.fms.feedlothealth.com',
  psmtReportingUrl: 'https://psmt-reporting.qa.fms.feedlothealth.com'
};
