import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@system/core/config';

export const APP_CONFIG_DEV: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'local',
  production: false,
  apiUrl: 'http://host.docker.internal:5136/api',
  signalRUrl: 'http://host.docker.internal:5136/PushNotification',
  heartbeatUrl: 'http://host.docker.internal:5136/api/Heartbeat',
  showDevFeature: true,
  authServerUrl: 'http://host.docker.internal:46000',
  psmtReportingUrl: 'http://host.docker.internal:5789'
};
