import { setRemoteDefinitions } from '@nx/angular/mf';
import { getAppConfig } from './utils';

const appConfig = getAppConfig();
const definitions = {
  'psmt-reporting': appConfig.psmtReportingUrl
}

setRemoteDefinitions(definitions);

import('./bootstrap').catch((err) => console.error(err))