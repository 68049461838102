import {
  APP_CONFIG_DEV,
  APP_CONFIG_FMS_DEV,
  APP_CONFIG_FMS_PROD,
  APP_CONFIG_FMS_QA,
  APP_CONFIG_FMS_STAGING,
  APP_CONFIG_FMS_UAT
} from '../configs';
import { AppConfig } from '@system/core/config';

export function getAppConfig(): AppConfig {
  const hostname = window.location.hostname;

  switch (hostname) {
    case 'system.dev.fms.feedlothealth.com':
      return APP_CONFIG_FMS_DEV;
    case 'system.qa.fms.feedlothealth.com':
      return APP_CONFIG_FMS_QA;
    case 'system.uat.fms.feedlothealth.com':
      return APP_CONFIG_FMS_UAT;
    case 'system.staging.fms.feedlothealth.com':
      return APP_CONFIG_FMS_STAGING;
    case 'system.frm.feedlothealth.com':
      return APP_CONFIG_FMS_PROD;
    default:
      return APP_CONFIG_DEV;
  }
}