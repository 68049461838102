import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@system/core/config';

export const APP_CONFIG_FMS_UAT: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'uat',
  production: false,
  apiUrl: 'https://system-api.uat.fms.feedlothealth.com/api',
  signalRUrl: 'https://system-api.uat.fms.feedlothealth.com/PushNotification',
  heartbeatUrl: 'https://system-api.uat.fms.feedlothealth.com/api/Heartbeat',
  showDevFeature: true,
  authServerUrl: 'https://identity.uat.fms.feedlothealth.com',
  psmtReportingUrl: 'https://psmt-reporting.uat.fms.feedlothealth.com'
};
